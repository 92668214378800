body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: aliceblue;
}

#root{
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  height: 100vh;
}

.row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.row.center{
  justify-content: center;
}

.row.left{
  justify-content: left;
}

.row.right{
  justify-content: right;
}

.row.top{
  align-items: flex-start;
}

.row.full{
  width: 100%;
}

.bottom{
  bottom: 0;
  position: fixed;
  left: 0;
}

.row-bottom{
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 0;
  background:
    linear-gradient(#04374b, transparent),
    linear-gradient(to top, rgb(0, 128, 255), transparent),
    linear-gradient(to bottom, rgb(127, 194, 248), transparent);
  overflow-x: scroll;
  overflow-y: none;
}
.row.space{
  justify-content: space-around;
  width: 100%;
  /* backdrop-filter: blur(2rem); */
  padding: 0.2rem;
}

.col-mini{
  flex: 0.1 0.1 0rem;
  padding: 1rem;
}
.col-mini.top{
  top: 0;
}

.col-0{
  flex: 1 1 0rem;
  padding: 1rem;
}

.col-1{
  flex: 1 1 25rem;
  padding: 1rem;
}

.col-2{
  flex: 2 1 50rem;
}

.col.full{
  flex: 2 1 50rem;
  width: 100%;
}

.box{
  width: 25vw;
  height: 50vh;
  background-color: rgb(194, 218, 250);
  border-radius: 2rem;
}

@media (max-width: 100rem)  {
  .box{
    width: 80vw;
    height: 80vh;
    background-color: rgb(194, 218, 250);
  }
  .space-around {
    padding: 0.1rem;
  }
  
  .space-around.more {
    padding: 0.1rem;
  }
}
@media (min-width: 100rem)  {
  .space-around {
    padding: 0.5rem;
  }
  
  .space-around.more {
    padding: 0.2rem;
  }
}

.pwnNotification {
  position: absolute;
  animation: pwnNotification 2s 1s infinite;
  background-color: aliceblue;
  background-image: url("https://media1.tenor.com/m/S9JDsXi8lboAAAAd/joker-the-dark-knight.gif");
  background-size: contain;
}
.pwnNotification2{
  position: absolute;
  animation: pwnNotification 2s 2s infinite;
  background-color: aliceblue;
  background-image: url("https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExdDBmbG1oM2Z6M3NhZmU1Yzh5YWFvd2J0d2toaG9iMzJmN3FsbDN3biZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/P9sGDIowyf7PYyymmn/giphy.webp");
  background-size: contain;
}
.pwnNotification3{
  right: 40vw;
  position: absolute;
  animation: pwnNotificationReverse 2s 2s infinite;
  background-color: aliceblue;
  background-image: url("https://static.wikia.nocookie.net/3dac4769-9cbe-4ddc-a31a-803b94b598b2/scale-to-width/755");
  background-size: contain;
}
.pwnNotification4{
  right: 40vw;
  position: absolute;
  animation: pwnNotificationReverse 5s 2s infinite;
  background-color: aliceblue;
  background-image: url("https://steamuserimages-a.akamaihd.net/ugc/824565659827511031/2D22601E723297CB9098C29203F6C090B1863A7D/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false");
  background-size: contain;
}

@keyframes pwnNotification {
  0% {
    width: 0;
    height: 0;
  }
  50%{
    width: 10rem;
    height: 10rem;
    /* transform: rotate(90deg); */
  }
  75%{
    transform: rotate(360deg) translateY(-10rem);
  }
  100%{
    transform: rotate(90deg);
    width: 0;
    height: 0;
  }
}
@keyframes pwnNotificationReverse {
  0% {
    width: 0;
    height: 0;
  }
  50%{
    width: 10rem;
    height: 10rem;
    /* transform: rotate(90deg); */
  }
  75%{
    transform: rotate(-360deg) translateY(-10rem);
  }
  100%{
    transform: rotate(-90deg);
    width: 0;
    height: 0;
  }
}



.blurred{
  color: transparent;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
  user-select: none;
}

.mini-box{
  max-width: 10rem;
}

.fa{
  font-size: 1rem;
}

.interactions{
  background-color: rgb(168, 187, 221);
  color:rgb(0, 108, 248);
  padding: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  bottom: 0;
  box-shadow: 0.2rem 0.5rem #04374b;
}

.secret{
  color: rgb(194, 218, 250);
}